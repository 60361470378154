import { toHumanDate, toHumanDateTime } from '@/helpers/filters/toHumanDate';
import { numberToPhone, phoneToNumber } from '@/helpers/filters/phone';

/**
 * @global
 */
export const $filters = {
  toHumanDate,
  toHumanDateTime,
  // phoneToNumber,
  // numberToPhone,
};

export default $filters;
